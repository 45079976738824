import { capitalCase } from "change-case";
import moment from "moment";

const forTrip = (data, line) => {
  const temp = {};
  temp.Line = line;
  temp["Username"] = data?.username;
  temp.Name = `${data?.user?.user_profile?.first_name ?? ""} ${
    data?.user?.user_profile?.last_name ?? ""
  }`.trim();
  temp.eMail = capitalCase(data?.email);
  temp["Mobile"] = data?.mobile;
  temp["User Type"] = capitalCase(data?.user_type);
  temp["Period"] = data?.period;
  temp["DOJ"] = moment(data?.created_at).format("DD/MM/YYYY");

  return temp;
};

export default forTrip;
