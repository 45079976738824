import { Box, Button, useMediaQuery } from "@mui/material";
import moment from "moment";
import { exportToExcel } from "react-json-to-excel";
import Iconify from "src/components/Iconify";
import axiosInstance from "src/utils/axios";
import { getUrl } from "../config";
import helper from "./helper";
import Translate from "src/components/translate";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { verifyInput } from "../hooks/use-filter";
import { useSnackbar } from "notistack";

const Export = ({ type, methods }) => {
  const { enqueueSnackbar } = useSnackbar();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const buttonProps = {
    size: isSmallScreen ? "small" : "medium",
  };
  const [loading, setLoading] = useState(false);
  const handleExport = async () => {
    setLoading(true);
    const URI = `api/admin/${getUrl(type)}`;

    const filter = await verifyInput(methods.getValues());
    const fileName = `${type}_report-${moment().format("DD-MMM-YYYY:HH:mm")}`;

    try {
      const { data } = await axiosInstance.get(URI, {
        params: {
          excel: 1,
          ...filter,
        },
      });
      const handler = helper(type);
      const exportData = data?.data?.map((item, i) => handler(item, i + 1));
      exportToExcel(exportData, fileName);
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("Unable to export data", { variant: "error" });
      setLoading(false);
      console.log(err);
    }
  };
  return (
    <Box sx={{ justifyContent: "flex-end", display: "flex", mb: 3 }}>
      <LoadingButton
        loading={loading}
        {...buttonProps}
        onClick={handleExport}
        startIcon={<Iconify icon="uil:export" />}
        variant="contained"
        size="medium"
      >
        <Translate>export</Translate>
      </LoadingButton>
    </Box>
  );
};

export default Export;
