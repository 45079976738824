import forCredit from "./for-credit";
import forIncome from "./for-income";
import forReward from "./for-reward";
import forSales from "./for-sales";
import forStake from "./for-stake";
import forTrip from "./for-trip";

const helper = (type) => {
  switch (type) {
    case "stake": {
      return forStake;
    }
    case "salespartner": {
      return forSales;
    }
    case "income": {
      return forIncome;
    }
    case "withdrawal": {
      return forCredit;
    }
    case "bitzClub": {
      return forReward;
    }
    case "trip": {
      return forTrip;
    }
  }
};

export default helper;
