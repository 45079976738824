import moment from "moment";
import ParseDate from "src/components/parse-date";

const forStake = (data, line) => {
  const dateAndTime = (dates) => {
    const date = moment(dates);
    const datePlus365 = date.add(365, "days");
    const formattedDate = datePlus365.format("DD/MM/YYYY");
    return formattedDate; // Return a formatted string instead of JSX
  };

  const calculateDaysLeft = (date) => {
    const daysLeft = 365 - date;
    return daysLeft;
  };

  const temp = {};
  temp.Line = line;
  temp["Investor ID"] = data?.username;
  temp.Name = `${data?.first_name ?? ""} ${data?.last_name ?? ""}`.trim();
  temp["eMail"] = data?.email;
  temp["Mobile"] = data?.mobile;
  temp["Partner ID"] = data?.sales_partner;
  temp["Plan"] = data?.plan_name;
  temp["Active Status"] = data?.surrender_date ? "Surrendered" : "Active";
  temp["Capital"] = data?.investment_amount;
  temp["Number of Days"] = `365/${calculateDaysLeft(data?.days_left)}`; // Correct the calculation
  temp["Profit Type"] = data?.profit_type;
  temp["Mature Date"] = dateAndTime(data?.created_at); // Return string instead of JSX
  temp["DOI"] = moment(data?.created_at).format("DD/MM/YYYY");

  return temp;
};

export default forStake;
