import { capitalCase } from "change-case";
import moment from "moment";

const forIncome = (data, line) => {
  const temp = {};
  temp.Line = line;
  temp["Account ID"] = data?.user?.prior_username
    ? data?.user?.prior_username
    : data?.user?.username;
  temp["From Account ID"] = data?.fromuser?.username;
  temp["Source Type"] = capitalCase(data?.payment_type || "");
  temp["Credit"] = data?.total_amount;
  temp["Date"] = moment(data?.created_at).format("DD/MM/YYYY");
  return temp;
};

export default forIncome;
