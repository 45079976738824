import { capitalCase } from "change-case";
import moment from "moment";

const forCredit = (data, line) => {
  const temp = {};
  temp.Line = line;
  temp["Account ID"] = data?.username;
  temp["Source Type"] =
    data?.type === "pending"
      ? "Direct Withdrawal"
      : capitalCase(data?.type || "");
  temp.Amount = data?.amount;
  temp["Date"] = moment(data?.created_at).format("DD/MM/YYYY");
  return temp;
};

export default forCredit;
