import { capitalCase } from "change-case";
import moment from "moment";

const forReward = (data, line) => {
  const temp = {};
  temp.Line = line;
  temp["Account ID"] = data?.user?.username;
  temp.Name = `${data?.user?.user_profile?.first_name ?? ""} ${
    data?.user?.user_profile?.last_name ?? ""
  }`.trim();
  temp.Rank = capitalCase(data?.rank);
  temp["Year"] = data?.year;
  temp["Month"] = capitalCase(data?.month);
  temp["Reward Amount"] = data?.total_amount;
  temp["Club"] = capitalCase(data?.club);
  temp["Date"] = moment(data?.created_at).format("DD/MM/YYYY");

  return temp;
};

export default forReward;
