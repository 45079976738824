import moment from "moment";

const forSales = (data, line) => {
  const temp = {};
  temp.Line = line;
  temp["Partner ID"] = data?.username;
  temp.Name = `${data?.user_profile?.first_name ?? ""} ${
    data?.user_profile?.last_name ?? ""
  }`.trim();
  temp.eMail = data?.email;
  temp["Mobile"] = data?.user_profile?.mobile;
  temp["Total Members"] = data?.total_members;
  temp["Total Investment"] = data?.total_investment;
  temp["DOJ"] = moment(data?.created_at).format("DD/MM/YYYY");

  return temp;
};

export default forSales;
